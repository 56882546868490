<template>
  <div class="card-promo">
    <CardPromo v-for="(card, index) in cards"
               :key="index"
               :imageLargeScreen="getImgUrl(card.imageLargeScreen, 'jpg')"
               :imageMobile="getImgUrl(card.imageMobile, 'jpg')"
               :title="card.title"
               :description="card.description"
               :button="card.button"
               :to="card.to"
               :inverted="isInverted(index)" />
  </div>
</template>

<script>
import CardPromo from '@/components/util/CardPromo';
import Helpers from '@/common/helpers.js';

export default {
  name: 'HomepageCards',
  components: {
    CardPromo,
  },
  data() {
    return {
      cards: [
        {
          imageLargeScreen: 'promo-1',
          imageMobile: 'promo-1-mobile',
          title: this.$t('homepageCards.firstCard.title'),
          description: this.$t('homepageCards.firstCard.description'),
          button: this.$t('common.domainAuctions.title'),
          to: '/auctions',
        },
        {
          imageLargeScreen: 'promo-2',
          imageMobile: 'promo-2-mobile',
          title: this.$t('homepageCards.secondCard.title'),
          description: this.$t('homepageCards.secondCard.description'),
          button: this.$t('common.domainTransfer.text'),
          to: '/domain-transfer',
        },
        {
          imageLargeScreen: 'promo-3',
          imageMobile: 'promo-3-mobile',
          title: this.$t('homepageCards.thirdCard.title'),
          description: this.$t('homepageCards.thirdCard.description'),
          button: this.$t('common.domainSearch.title'),
          to: '/domain-names',
        },
      ],
    };
  },
  methods: {
    getImgUrl(imageName, fileType) {
      return Helpers.getImgUrl(imageName, fileType);
    },
    isInverted(index) {
      return index % 2 !== 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.HomepageCards {
}
</style>
