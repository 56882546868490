<template>
  <div class="HomeView">
    <!-- <SearchDomain /> -->
    <!-- <SearchBlock :mainMessage="messages.searchBlockMain" :placeholderText="messages.searchBlockPlaceholderText"/> -->
    <SearchBlockHeader />
    <!-- <PromoBannerBlock/> -->
    <div v-if="isAuthenticated" class="o-container-gray">
      <UserAuctions :title="messages.userAuctionsTitle"/>
    </div>
    <DomainList />
    <HomepageCards />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import SearchDomain from '@/components/SearchDomain';
// import SearchBlock from '@/components/SearchBlock';
import SearchBlockHeader from '@/components/SearchBlockHeader.vue';
// import PromoBannerBlock from '@/components/PromoBannerBlock';
import UserAuctions from '@/components/UserAuctions';
import DomainList from '@/components/DomainList';
import HomepageCards from '@/components/homepage/HomepageCards';

export default {
  name: 'HomeView',
  components: {
    // SearchDomain,
    // SearchBlock,
    SearchBlockHeader,
    // PromoBannerBlock,
    UserAuctions,
    DomainList,
    HomepageCards
  },
  data() {
    return {
      messages: {
        auctionCarouselCardTitle: this.$t('homeView.auctionCarouselCardTitle'),
        userAuctionsTitle: this.$t('userAuctions.title'),
        saleCarouselCardTitle: this.$t('homeView.saleCarouselCardTitle'),
        searchBlockMain: this.$t('homeView.searchBlockMain'),
        searchBlockPlaceholderText: this.$t('homeView.searchBlockPlaceholderText')
      }
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated'
    ])
  }
};
</script>

<style lang="scss" scoped>
.HomeView {
  @media (max-width: 915px) {
    overflow: hidden;
  }
}
</style>
