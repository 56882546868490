<template>
  <div class="CardPromo" :class="{ 'card-promo-inverted': inverted}">
    <div :style="backgroundImageSource" class="card-promo-image-container o-image-bg-responsive"></div>
    <div class="card-promo-text-container">
      <h2 class="o-default-h2 caption-title u-font-heading-regular">{{ title }}</h2>
      <p class="u-font-body-regular card-promo-text-description u-text-justify">{{ description }}</p>
      <router-link class="o-default-body" :to="to">
        <md-button class="md-raised md-primary navigate-btn u-display-block ">
          {{ button }}
        </md-button>
      </router-link>
    </div>
  </div> <!-- /.CardPromo -->
</template>

<script>
export default {
  name: 'CardPromo',
  props: {
    button: String,
    description: String,
    imageLargeScreen: {
      type: String,
      required: true
    },
    imageMobile: {
      type: String,
      required: true
    },
    title: String,
    to: String,
    inverted: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    backgroundImageSource() {
      return {
        backgroundImage: `url(${this.getImageSource()})`
      };
    },
  },
  methods: {
    getImageSource() {
      if (window.innerWidth < 1024) {
        return this.imageMobile;
      } else {
        return this.imageLargeScreen;
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.getImageSource.bind(this));
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getImageSource.bind(this));
  },
};
</script>

<style lang="scss" scoped>
.CardPromo {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: stretch;
  margin: 4rem 1.6rem;
  min-height: 50rem;
  @include desktop {
    flex-direction: row;
    margin: 4rem 8rem;
  }

  &.card-promo-inverted {
    @include desktop {
      flex-direction: row-reverse;
    }

    .card-promo-text-container {
      @include desktop {
        padding: 4rem 4rem 4rem 0;
      }
    }
  }

  .card-promo-image-container,
  .card-promo-text-container {
    display: flex;
    flex-basis: 50%;
    flex-wrap: wrap;
  }

  .card-promo-text-container {
    align-items: stretch;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    justify-content: center;
    line-height: $type-scale-line-height-4-fallback;
    line-height: $type-scale-line-height-4;
    @include desktop {
      padding: 4rem 0 4rem 4rem;
      gap: 1rem;
    }
  }

  .card-promo-image-container {
    min-height: 46rem;
    @include desktop {
      min-height: 100%;
    }
  }

  .caption-title {
    font-size: $type-scale-size-4-fallback;
    font-size: $type-scale-size-4;
    line-height: $type-scale-line-height-3-fallback;
    line-height: $type-scale-line-height-3;
    @include desktop {
      font-size: $type-scale-size-3-fallback;
      font-size: $type-scale-size-3;
    }
  }

  .card-promo-text-description {
    font-size: $type-scale-size-6-fallback;
    font-size: $type-scale-size-6;
    letter-spacing: 0;
    line-height: $type-scale-line-height-6-fallback;
    line-height: $type-scale-line-height-6;
  }

  .navigate-btn {
    margin: 1rem 0;
    padding: 0 2.5rem;
  }
}
</style>
