<template>
  <section class="SearchBlockHeader" :class="{ 'extended-template': extendedTemplate }">
    <div class="o-overlay-7"></div>
    <div class="o-container">
      <div class="search-block-header-content">
        <div>
          <h2 class="search-block-header-title u-font-heading-medium o-default-h2 u-strong">{{ $t('common.domainSearch.title') }}</h2>
          <p class="search-block-header-description u-font-body-medium o-default-body">{{ $t('common.domainSearch.description') }}</p>
        </div>
        <form class="search-block-header-form" @submit.prevent>
          <md-autocomplete v-model="domainsToCheck"
                           :md-options="searchedDomains"
                           @keyup.enter="checkAvailability(domainsToCheck)"
                           md-layout="box">
            <label>{{ $t('searchBlockHeader.findDomains') }}</label>
            <md-button type="submit"
                       class="md-dense md-raised md-primary search-btn"
                       @click="checkAvailability(domainsToCheck)">
              {{ $t('common.actions.search') }}
            </md-button>
          </md-autocomplete>
        </form>
        <span v-if="emptyField" class="u-red u-text-center u-strong md-error">{{ $t('searchBlockHeader.emptyDomainNamesError')}}</span>
        <p class="search-block-header-description transfer-domain-description o-default-body u-font-heading-bold">
          {{ $t('common.or') }}
          <router-link :to="links.domainTransfer.path" class="u-light-green">{{ links.domainTransfer.text }}</router-link>
          {{ $t('searchBlockHeader.toInreg') }}
        </p>
      </div>
    </div> <!-- /.o-container -->
    <md-snackbar :md-position="snackbar.position"
                 :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showSnackbar"
                 md-persistent>
      <span>{{ $t('searchBlockHeader.snackbarError') }}</span>
      <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
    <md-snackbar :md-position="snackbarErrorEmptyDomainNames.position"
                 :md-duration="snackbarErrorEmptyDomainNames.duration"
                 :md-active.sync="snackbarErrorEmptyDomainNames.showSnackbar"
                 md-persistent>
      <span>{{ $t('searchBlockHeader.snackbarErrorEmptyDomainNames') }}</span>
      <md-button class="md-primary" @click="snackbarErrorEmptyDomainNames.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </section> <!-- /.SearchBlockHeader -->
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CHECK_DOMAIN
} from '@/store/actions.type';
import {
  SET_CHECK_AVAILABILITY_ERROR,
  SET_SHOW_SEARCHED_DOMAINS,
} from '@/store/mutations.type';

export default {
  name: 'SearchBlockHeader',
  props: {
    extendedTemplate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      domainsToCheck: '',
      emptyField: false,
      links: {
        domainTransfer: {
          path: '/domain-transfer',
          text: this.$t('searchBlockHeader.domainTransfer')
        }
      },
      searchedDomains: [],
      snackbar: {
        showSnackbar: false,
        duration: 8000,
        position: 'center',
      },
      snackbarErrorEmptyDomainNames: {
        showSnackbar: false,
        position: 'center',
        duration: 5000
      },
    };
  },
  created() {
    this.domainsToCheck = this.$route.query.domainsToCheck;

    if (this.domainsToCheck && this.domainsToCheck != '') {
      this.checkAvailability(this.domainsToCheck);
    } else {
      this.$store.commit(SET_SHOW_SEARCHED_DOMAINS, false);
    }
  },
  computed: {
    ...mapGetters([
      'checkAvailabilityError',
      'isAuthenticated'
    ])
  },
  methods: {
    splitName(rawDomainNames) {
      const separators = [' ', ',', '  '];
      let domainNames = String(rawDomainNames).split(new RegExp(separators.join('|'), 'g'));

      domainNames = domainNames.filter(item => item);

      return domainNames;
    },
    checkAvailability(domainNames) {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
      } else if (domainNames) {
        domainNames = this.splitName(domainNames);

        if (this.$route.name !== 'domain-names') {
          this.$router.push({
            name: 'domain-names',
            query: {
              domainsToCheck: domainNames.toString()
            }
          })
        } else {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              domainsToCheck: domainNames.toString()
            }
          });
          this.$store.dispatch(CHECK_DOMAIN, domainNames).then(() => {
            if (this.checkAvailabilityError === true) {
              this.snackbar.showSnackbar = true;
              this.$store.commit(SET_CHECK_AVAILABILITY_ERROR, false);
            } else {
              this.$store.commit(SET_SHOW_SEARCHED_DOMAINS, true);
              this.emptyField = false;
            }
          })
        }
      } else if (!domainNames) {
        this.emptyField = true;
        this.snackbarErrorEmptyDomainNames.showSnackbar = true;
      }
    },
  },
};
</script>

<style lang="scss">
.SearchBlockHeader {

  .md-field.md-inline.md-autocomplete-box .md-input-action {
    background-color: $white;
    right: 9.5rem;

    @include mobile-360 {
      right: 10rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.SearchBlockHeader {
  background: url('../assets/images/promo-3.jpg') no-repeat center center;
  background-size: cover;
  height: 45rem;
  position: relative;
  @include desktop {
    align-content: center;
  }

  &.extended-template {
    background: url('../assets/images/page-header-register-domain.jpg') no-repeat center center;
    background-size: cover;
    height: 30rem;
    min-height: 30rem;
  }

  .o-container {
    height: 100%;
    position: relative;
    padding: 1.6rem 0;
  }

  .search-block-header-content {
    animation: fadein 2s;
    @keyframes fadein {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    max-width: 120rem;
    padding: 0 1rem 1.6rem;
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(100% - 1.6rem);
    @include tablet { width: 90%; }
    @include desktop { width: 100%; }

    .md-field.md-inline.md-autocomplete-box {
      margin: .4rem 0 .8rem;
      padding: 0;
    }

    .search-block-header-title {
      color: $white;
      padding-bottom: 1.6rem;
    }

    .search-block-header-description {
      color: $white;
    }

    .search-block-header-form {
      padding-top: .8rem;
    }

    .search-btn {
      border-radius: 0;
      font-size: 1.5rem;
      height: 5.2rem;
      margin: 0;
      max-width: 10rem;
      width: 10rem;
    }
  }
  .transfer-domain-description {
    margin-top: 2rem;
  }
}
</style>
