<template>
  <div class="DomainList">
    <div class="o-container-gray">
      <h3 class="domain-list-title o-default-h3">{{ $t('domainList.title') }}</h3>

      <div v-if="isDomainListLoading" class="auction-preview">
        <p class="o-default-h3 u-text-center">{{ $t('domainList.loading') }}</p>
        <Loader />
      </div> <!-- /.auction-preview -->

      <div v-else>
        <div v-if="auctions.length === 0" class="auction-preview">
          {{ $t('domainList.placeholder') }}
        </div>

        <div v-if="pages.length" class="o-container pagination-container">
          <span class="current-page" :pages="pages" :currentPage="currentPage">{{ $t('domainList.page') }} {{ currentPage }} {{ $t('domainList.of') }} {{ pages.length + 1 }}</span>
        </div>

        <md-table md-card v-model="auctions">
          <md-table-row slot="md-table-row"
                        slot-scope="{ item }"
                        @click.exact="goToAuctionView(item.tld, item.domain)"
                        @click.ctrl.exact="goToAuctionViewNewTab($event, {tld: item.tld, domain: item.domain} )"
                        @contextmenu.prevent="$refs.ctx.open($event, {tld: item.tld, domain: item.domain})">
              <md-table-cell :md-label="labels.domainName"
                             md-sort-by="name">
                {{ item.domain }}.{{ item.tld }}
              </md-table-cell>
              <md-table-cell :md-label="labels.auctionStatus"
                             md-sort-by="active"
                             class="auction-status"
                             v-bind:class="{ 'u-green': item.active }">
                {{ displayAuctionStatus(item.active) }}
              </md-table-cell>
              <md-table-cell :md-label="labels.timeLeft"
                             md-sort-by="secondsLeft"
                             v-bind:class="{ 'u-orange': item.active, 'u-red': item.closingSoon }">
                <countdown :left-time="item.secondsLeft * 1000">
                  <span slot="process"
                        slot-scope="{ timeObj }">
                    {{ showDays(timeObj.d) }} {{ showHours(timeObj.h) }} {{ showMinutes(timeObj.m) }} {{ showSeconds(timeObj.s) }}
                  </span>
                  <span slot="finish">
                    <span class="o-default-body-small u-strong u-red u-text-center auction-closed">{{ $t('domainList.auctionClosedTimeElapsed') }}</span>
                  </span>
                </countdown>
              </md-table-cell>
              <md-tooltip v-if="tooltipEnabled" md-direction="bottom">
                {{ $t('domainList.clickToNavigateToAuction') }}
              </md-tooltip>
              <context-menu ref="ctx"
                            @ctx-open="onCtxOpen"
                            @ctx-close="onCtxClose"
                            @ctx-cancel="resetCtxLocals">
                <li class="o-context-menu-li" @click="goToAuctionViewNewTab($event, contextMenuData)">
                  {{ $t('domainList.openAuctionInNewTab') }}
                </li>
              </context-menu>
          </md-table-row>
        </md-table>

        <div class="o-container pagination-container">
          <PaginationBlock :pages="pages" :currentPage.sync="currentPage"/>
        </div>
      </div> <!-- v-else -->
    </div> <!-- /.o-container-grey -->
  </div> <!-- /.DomainList -->
</template>

<script>
import { mapGetters } from 'vuex';
import ContextMenu from 'vue-context-menu';
import { FETCH_AUCTIONS } from '@/store/actions.type';
import Loader from '@/components/util/Loader';
import PaginationBlock from '@/components/util/PaginationBlock';
import Helpers from '@/common/helpers.js';

export default {
  name: 'DomainList',
  components: {
    ContextMenu,
    PaginationBlock,
    Loader
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 75
    }
  },
  data() {
    return {
      currentPage: parseInt(this.$route.query.sourcePageNumberAuctions) || 1,
      contextMenuData: {},
      tooltipEnabled: true,
      labels: {
        domainName: this.$t('domainList.domainName'),
        auctionStatus: this.$t('domainList.auctionStatus'),
        timeLeft: this.$t('domainList.timeLeft')
      },
      status: {
        auctionOnGoing: this.$t('domainList.auctionOnGoing'),
        auctionClosed: this.$t('domainList.auctionClosed'),
      }
    };
  },
  computed: {
    listConfig() {
      const filters = {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage
      };
      return {
        filters
      };
    },
    pages() {
      if (this.isDomainListLoading || this.auctionsCount <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.auctionsCount / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    },
    ...mapGetters([
      'auctionsCount',
      'isAuthenticated',
      'isDomainListLoading',
      'auctions'
    ])
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.filters.offset = (newValue - 1) * this.itemsPerPage;
      this.fetchAuctions();
    },
  },
  mounted() {
    let sourcePageNumberAuctions = this.$route.query.sourcePageNumberAuctions;

    if (sourcePageNumberAuctions) {
      this.listConfig.filters.offset = (sourcePageNumberAuctions - 1) * this.itemsPerPage;
    } else {
      this.listConfig.filters.offset = 0 * this.itemsPerPage;
    }
    this.fetchAuctions();
  },
  methods: {
    fetchAuctions() {
      this.$store.dispatch(FETCH_AUCTIONS, this.listConfig);
    },
    displayAuctionStatus(itemStatus) {
      if (itemStatus == true) {
        return this.$t('domainList.auctionOnGoing');
      } else {
        return this.$t('domainList.auctionClosed');
      }
    },
    resetPagination() {
      this.listConfig.offset = 0;
      this.currentPage = 1;
    },
    showDays(days) {
      if (parseInt(days) > 0) {
        if (parseInt(days) > 1) {
          return `${days} ${this.$t('common.date.days')} `;
        } else if (parseInt(days) == 1) {
          return `${days} ${this.$t('common.date.day')} `
        } else {
          return '';
        }
      }
    },
    showHours(hours) {
      if (parseInt(hours) > 0) {
        if (parseInt(hours) == 0 || parseInt(hours) > 1) {
          return `${hours} ${this.$t('common.date.hours')} `;
        } else if (parseInt(hours) == 1) {
          return `${hours} ${this.$t('common.date.hour')} `
        } else {
          return '';
        }
      }
    },
    showMinutes(minutes) {
      if (parseInt(minutes) > 0) {
        if (parseInt(minutes) == 0 || parseInt(minutes) > 1) {
          return `${minutes} ${this.$t('common.date.min')} `;
        } else if (parseInt(minutes) == 1) {
          return `${minutes} ${this.$t('common.date.min')} `
        } else {
          return '';
        }
      }
    },
    showSeconds(seconds) {
      if (parseInt(seconds) > 0) {
        if (parseInt(seconds) == 0 || parseInt(seconds) > 1) {
          return `${seconds} ${this.$t('common.date.sec')} `;
        } else if (parseInt(seconds) == 1) {
          return `${seconds} ${this.$t('common.date.sec')} `
        } else {
          return '';
        }
      }
    },
    goToAuctionView(tld, domain) {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
        return;
      }
      Helpers.goToView('auctions', tld, domain, this.currentPage, this.$router);
    },
    goToAuctionViewNewTab(e, context) {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
        return;
      }
      let route = this.$router.resolve(`/auctions/${context.tld}/${context.domain}`);
      window.open(route.href, '_blank');
    },
    onCtxOpen(locals) {
      this.contextMenuData = locals;
      this.tooltipEnabled = false;
    },
    onCtxClose() {
      this.tooltipEnabled = true;
    },
    resetCtxLocals() {
      this.contextMenuData = {};
      this.tooltipEnabled = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.DomainList {

  .md-table-row {
    cursor: pointer;
  }

  .md-table-cell {
    text-align: center;
  }

  .o-container-gray {
    padding-top: 3.2rem;
  }

  .auction-preview {

    .o-default-h3 {
      margin-bottom: 1.6rem;
    }
  }

  .auction-status {
    color: $palette-error;
  }

  .auction-closed {
    padding: .6rem 3.2rem;
  }

  .domain-list-title {
    display: inline-block;
  }

  .pagination-container {
    padding-top: .4rem;
  }
}
</style>
